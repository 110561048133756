.guessQuizContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 24px;
  border-radius: 24px;
  gap: 24px;
}

.guessQuizTitle {
  width: 100%;
  margin-left: 16px;
  font-size: 18px;
  font-weight: 775;
  line-height: 26px;
}

.guessQuizCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--color-white);
  padding: 12px;
  border-radius: 10px;
}

.guessQuizImg {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-basic-8);
  height: 286px;
  width: 286px;
  font-size: 20px;
  font-style: italic;
  border-radius: 6px;
}

.guessQuizInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 52px;
  padding: 4px 8px;
}

.nftInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.guessQuizAuthor {
  font-size: 13px;
  font-weight: 775;
  line-height: 22px;
}

.guessQuizPrice {
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
}

.guessQuizForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4px 8px;
}

.guessQuizRangeInput {
  height: 2px;
  width: 100%;
  margin: 36px 0;
}

.guessQuizRangeInput::-moz-range-track {
  background-color: var(--color-basic-8);
}

.guessQuizRangeInput::-moz-range-thumb {
  background-color: var(--color-basic-1);
  height: 32px;
  width: 32px;
  border: none;
  border-radius: 50%;
}

.guessQuizRangeInput::-moz-range-progress {
  background-color: var(--color-basic-1);
}

.guessQuizInputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 2px;
}

.guessQuizUserPrice {
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
}
