.chatContainer {
  position: fixed;
  right: 30px;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-basic-1);
  width: 450px;
  padding: 24px;
  border-radius: 24px;
  gap: 32px;
}

.chatUserAnswers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--color-white-08);
  width: 100%;
  padding: 12px;
  margin-top: 32px;
  border-radius: 20px;
  gap: 16px;
}

.chatUserReplyText {
  padding: 4px 8px;
  color: var(--color-white-6);
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
}

.chatUserAnswersBtns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
}

.answerResult {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  width: 100%;
  padding: 24px 32px;
  border-radius: 20px;
  box-sizing: border-box;
  gap: 42px;
}

.answerResultText {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}

@media screen and (max-width: 700px) {
  .chatContainer {
    right: 15px;
    bottom: 15px;
    width: 70%;
    padding: 16px;
  }

  .chatUserAnswersBtns {
    flex-direction: column;
  }
}

@media screen and (max-width: 390px) {
  .chatContainer {
    right: 5px;
    bottom: 5px;
    width: 80%;
    padding: 10px;
  }
}
