.checkListContainer {
  display: flex;
  width: 680px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: var(--color-basic-4);
}

.checkListContent {
  display: flex;
  width: 100%;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--color-white);
}

.checkListLessonInfo {
  display: flex;
  width: 100%;
  padding: 12px 12px 12px 8px;
  align-items: flex-start;
  gap: 12px;
  box-sizing: border-box;
}

.checkListTexts {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}

.checkListLessonNumber {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid var(--color-basic-1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  color: var(--color-basic-1);
}

.checkListLessonTitle {
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  align-self: center;
  color: var(--color-basic-1);
}

.checkListFinished {
  border-color: var(--color-basic-40);
  color: var(--color-basic-40);
}

.checkListLessonTitle.checkListFinished {
  text-decoration: line-through;
}

.checkListFinishIcon {
  display: flex;
  justify-self: flex-end;
  margin: 0 12px;
}
