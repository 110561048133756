.chatMsgContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 24px;
}

.chatMsgImg {
  height: 64px;
  width: 64px;
  border-radius: 50%;
}

.chatMsgContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 4px;
}

.chatMsgNickname {
  display: flex;
  align-items: center;
  color: var(--color-white);
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  gap: 8px;
}

.chatMsgDate {
  color: var(--color-white-4);
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
}

.chatMsgText {
  color: var(--color-white);
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
}

@media screen and (max-width: 700px) {
  .chatMsgImg {
    height: 48px;
    width: 48px;
  }

  .chatMsgNickname {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
}

@media screen and (max-width: 390px) {
  .chatMsgImg {
    height: 48px;
    width: 48px;
  }

  .chatMsgNickname {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
}
