.lessonTagsList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.lessonTag {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-basic-4);
  padding: 8px 16px;
  border-radius: 24px;
}

.lessonTagText {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
}

@media screen and (max-width: 390px) {
  .notion .lessonTagsList {
    margin-top: 60px;
  }
}
