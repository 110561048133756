.lessonCardContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-primary);
  padding: 24px;
  margin-bottom: 8px;
  border-radius: 24px;
}

.lessonCardInfo {
  justify-self: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 23px;
}

.lessonCardContent {
  width: 100%;
  background-color: var(--color-white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 12px 0 48px 0;
}

.btnOpenModal {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.lessonCardLabel {
  gap: 29px;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  margin-top: 12px;
}

.lessonCardTitle {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  width: 344px;
}

.lessonCardFinishCard {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.lessonCardFinishContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
  padding: 100px 0;
  border-radius: 24px;
}

.lessonCardFinishTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 144%;
  gap: 10px;
}

.lessonCardFinishTitleFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  gap: 8px;
}

.btnExplore {
  font-size: 18px;
}

.congratulationsIcon {
  height: 43px;
  width: 55px;
}

.lessonCardFinishTitleFooterLFG {
  color: var(--color-basic-1);
  text-align: center;
  font-feature-settings: "ss01" on;
  font-family: Lato;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 108%;
}

.lessonCardFinishTitleFooterShare {
  background-image: url("../assets/icons/share-bg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color: var(--color-primary);
  text-align: center;
  font-size: 18px;
  font-style: italic;
  font-weight: 600;
  line-height: 144%;
  letter-spacing: 0.36px;
  padding: 8px;
}
