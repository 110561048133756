@font-face {
  font-family: "Inter";
  src: local("Inter"), url(../assets/fonts/Inter.ttf) format("truetype");
}

body {
  font-size: 18px;
  color: var(--text-color-basic);
  margin: 0;
  padding: 0;
}

* {
  font-family: "Inter";
}

#root {
  height: 100vh;
  width: 100vw;
}

:root {
  --color-white: #fff;
  --color-white-4: #ffffff66;
  --color-white-6: #ffffff99;
  --color-white-08: #ffffff14;
  --color-gray: #ffff5440;
  --color-black: #000;
  --color-basic-1: #191c1f;
  --color-basic-4: #191c1f0a;
  --color-basic-8: #191c1f14;
  --color-basic-20: #191c1f33;
  --color-basic-40: #191c1f66;
  --color-basic-60: #191c1f99;
  --color-shadow: #1b20321a;
  --color-primary: #ffff54;
  --color-primary-1: #fff613;
  --color-success: #86f25e;
}
