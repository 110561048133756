.answerBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 50%;
}

.answerBlock.correct {
  background-color: var(--color-success);
}

.answerBlock.wrong {
  background-color: var(--color-basic-4);
}

.answerImg {
  height: 24px;
  width: 24px;
}
