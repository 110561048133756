.app {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.blurred {
  filter: blur(10px);
}

.mainScreenHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 10px;
  box-sizing: border-box;
}

.mainScreenInfo {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
}

@media screen and (max-width: 700px) {
  .mainScreenInfo {
    width: 358px;
  }

  /*Lesson Card*/
  .lessonCardContainer {
    padding: 24px;
  }
  .lessonCardTitle{
    font-size: 18px;
    width: 218px;
  }
  .btnOpenModal{
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  .btnExplore {
    font-size: 13px;
  }

  .lessonCardFinishTitle{
    width: 300px;
    font-size: 18px;
  }

  /*Lesson Tag*/
  .lessonTag {
    margin-bottom: 8px;
  }

  /*Check List*/
  .checkListContainer{
    width: 310px;
  }

  .checkListLessonTitle {
    width: 180px;
  }
}
