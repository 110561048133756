.container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  align-self: center;
  background-color: var(--color-basic-4);
  width: 100%;
  padding: 24px;
  margin-bottom: 8px;
  border-radius: 24px;
  gap: 24px;
}

.containerFinalStep {
  background: var(--color-primary);
  width: 100%;
}

.titleName {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  align-self: flex-start;
  padding: 10px;
  color: var(--color-basic-1);
}

.content {
  width: 100%;
  height: fit-content;
  background-color: var(--color-white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.answerContainer {
  margin-bottom: 26px;
  margin-top: 12px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.resultContainer {
  width: 90%;
  height: auto;
  background-color: var(--color-white);
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
  align-items: center;
  padding: 49px 0;
  gap: 10px;
}

.answerInput {
  height: auto;
  padding: 12px 12px 12px 16px;
  margin: 0 16px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 15px;
  gap: 24px;
}

.answerInput:has(input:checked) {
  background: var(--color-primary);
}

input[type="radio"] {
  appearance: none;
  background-color: var(--color-white);
  width: 24px;
  height: 24px;
  border: 3px solid var(--color-basic-8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="radio"]::before {
  content: "";
  width: 24px;
  border-radius: 50%;
  box-shadow: inset 1em 1em var(--color-white);
}

input[type="radio"]:checked {
  border: 7px solid var(--color-black);
}

.footerForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  width: 100%;
  margin: 0 16px;
  padding: 12px 12px 12px 16px;
}

.numberOfQuestion {
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  color: var(--color-basic-60);
}

@media screen and (max-width: 700px) {
  .titleName {
    padding: 0 10px 10px;
  }

  input[type="radio"] {
    width: 18px;
    height: 18px;
  }

  .answerInput {
    margin: 0;
  }
}
