.lessonModal {
  position: absolute;
  top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--color-white);
  padding: 32px;
  border-radius: 48px;
  z-index: 1;
  box-sizing: border-box;
}

/* Classes from notion API */

.notion {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.notion-h1 {
  margin: 260px 0;
  text-align: center;
  align-self: center;
  font-size: 72px;
  font-weight: 500;
  line-height: 80px;
  color: var(--color-basic-20);
}

.closeBtn {
  display: "flex";
  justify-content: "center";
  align-items: "center";
  align-self: flex-end;
  justify-self: flex-start;
}

@media screen and (max-width: 700px) {
  .notion {
    width: 100%;
  }

  .notion-h1 {
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 70px;
    margin: 180px 0;
  }

  .lessonModal {
    top: 60px;
  }
}

@media screen and (max-width: 390px) {
  .notion {
    width: 100%;
  }

  .notion-h1 {
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    margin: 148px 0;
  }

  .lessonModal {
    top: 30px;
  }
}
