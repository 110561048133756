.keyWord {
  background-color: var(--color-gray);
  width: fit-content;
  color: var(--color-basic-1);
  border-bottom: 2px solid var(--color-primary-1);
  word-break: keep-all;
  white-space: nowrap;
  flex-wrap: nowrap;
}

.keyWord:hover {
  background-color: var(--color-primary);
}

.keyDesc {
  position: absolute;
  display: flex;
  background-color: var(--color-white);
  max-width: 245px;
  padding: 10px 12px;
  border-radius: 12px;
  border: 1px solid var(--color-basic-8);
  box-shadow: 0px 6px 16px 0px var(--color-shadow);
}
